<template>
  <div class="list row">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Vorname" v-model="searchFields.forename" @keyup.enter="searchByPatientData"/>
        <input type="text" class="form-control" placeholder="Nachname" v-model="searchFields.surname" @keyup.enter="searchByPatientData"/>
          <button class="btn btn-outline-secondary"
                  @click="searchByPatientData()">
            Suchen
          </button>

        <div class="input-group-append">

        <button class="btn ms-3"
                :class="{ 'btn-success': showingStatus === this.STATUS_UNPRINTED, 'btn-danger': showingStatus === this.STATUS_ALL }"
                  @click="toggleAllAndPrinted">
                  {{ showingStatus ? 'Ungedruckt' : 'Alle' }}
          </button>
        </div>

      </div>
    </div>
    <div class="col-lg-5 col-sm-6">
      <h1 class="position-relative display-6">
        Immunkarten <span class="badge bg-primary">{{ sum }}</span>
        <span class="form-check d-inline">
          <input class="form-check-input"
                 type="checkbox"
                 v-model="selectAll">
        </span>
      </h1>
      <div class="scroll-container-vertical border rounded-1 p-2">
        <div v-for="(group, index) in immunkartesGroupedByMandant"
           :key="index"
           class="mb-3">
          <div class="form-check">
            <input class="form-check-input"
                   type="checkbox"
                   :id="group._id._id"
                   @click="selectAllMandant(group.immunkartes, $event.target.checked)"
                   :checked="isAllImmunkartesInSelected(group.immunkartes)">
            <label class="h6" :for="group._id._id">
              {{ group._id.firmenbezeichnung.voll }}
            </label>
          </div>
        <ul class="list-group">
          <li class="list-group-item"
              :class="{ active: immunkarte._id === currentIndex }"
              v-for="(immunkarte) in group.immunkartes"
              :key="immunkarte._id"
              @click="setActiveImmunkarte(immunkarte, immunkarte._id)"
          >
            <div class="form-check">
              <input class="form-check-input"
                     type="checkbox"
                     :value="immunkarte._id"
                     :id="immunkarte._id"
                     v-model="selected"
                     @click="updatePrintJob(immunkarte._id, $event.target.checked)">
              <label :for="immunkarte._id">
                {{ immunkarte.patient.surname }}, {{ immunkarte.patient.forename }}
              </label>
            </div>
          </li>
        </ul>
      </div>
      </div>
    </div>
    <div class="col-lg-4 col-sm-6 mb-3">
      <div v-if="current" class="detail-view">
        <h5>
          {{ current.patient.surname }}, {{ current.patient.forename }}
        </h5>
        <dl class="m-1">
          <dt v-if="current.createdBy">
            Erstellt:
          </dt>
          <dd v-if="current.createdBy">
            {{ moment(current.createdAt) }} von {{ current.createdBy.forename }} {{ current.createdBy.surname }}
          </dd>
          <dt>
            Druckhistorie:
          </dt>
          <dd>
            Gedruckt: <span class="badge" :class="[ current.print.hasBeenPrinted ? 'bg-success' : 'bg-danger' ]">
              <span v-if="current.print.hasBeenPrinted">Ja</span>
              <span v-else>Nein</span>
            </span>
            <div v-for="printHistory in current.print.history" :key="printHistory.id">
              <span class="badge bg-primary">{{ moment(printHistory.date) }}</span>
              {{ printHistory.user.forename }} {{ printHistory.user.surname }}:
              {{ printHistory.action }}
            </div>
          </dd>

          <dt>Geburtsdatum:</dt>
          <dd>{{ current.patient.dateOfBirth.substring(0, 10) }}</dd>


          <div v-if="current.immunityType === 'recovery'"> <!-- else it is a vaccination certificate -->
            <dt>Datum des positiven Tests:</dt>
            <dd>
              {{ current.dateOfPositiveTest.substring(0, 10) }}
            </dd>
          </div>

          <div v-else>

            <dt>Impfung:</dt>
            <dd>
              {{ current.vaccination.dose }} / {{ current.vaccination.ofDoses }}<br>
            </dd>
            <dt>Datum der Impfung:</dt>
            <dd>
              {{ current.vaccination.date.substring(0, 10) }}
            </dd>

            <dt>Mitgliedsstaat der Impfung:</dt>
            <dd>
              {{ current.vaccination.country }}
            </dd>

            <dt>Name des Impfstoffs:</dt>
            <dd>
              {{ current.vaccine.name }}
            </dd>
            <dt>Impfstofftyp:</dt>
            <dd>
              {{ current.vaccine.type }}
            </dd>
            <dt>Impfstoffhersteller:</dt>
            <dd>
              {{ current.vaccine.manufacturer }}
            </dd>

          </div>

          <dt>Eindeutige Zertifikatskennung:</dt>
          <dd>{{ current.uci }}</dd>

          <dt>Zertifikats-Aussteller:</dt>
          <dd>{{ current.certificate.issuer }}</dd>

          <dt>QR-Code:</dt>
          <dd>
            <img
                v-bind:src="'data:'+
                current.certificate.qrCode.contentType+
                ';base64,'+current.certificate.qrCode.data"
                alt="QR-Code"
                style="width: 200px;"
            />
          </dd>
        </dl>

        <button class="btn btn-danger" @click="deleteImmunkarte">
          Immunkarte löschen
        </button>

        <button v-if="current.print.hasBeenPrinted" class="btn btn-warning" @click="markImmunkarteUnprinted">
          Immunkarte als ungedruckt markieren
        </button>
      </div>
    </div>
    <div v-if="isImmunkartePrinter" class="col-lg-3 col-sm-6">
      <h4 class="position-relative display-6">
        Druck
        <span class="badge bg-info">{{ selected.length }}</span>
      </h4>
      <button class="btn btn-warning" @click="printSelectedImmunkartes">Druck starten</button>
      <p v-if="normalMessage" class="mt-3">
        {{ normalMessage }}
      </p>
      <p v-if="errorMessage" class="text-danger mt-3">
        Fehler: {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import ImmunkarteService from "../services/immunkarte.service";
import AuthService from "../services/auth.service";
import moment from "moment";

export default {
  name: "immunkartes-list",

  data() {
    return {
      STATUS_ALL: 0,
      STATUS_UNPRINTED: 1,
      selected: [],
      immunkartesGroupedByMandant: [],
      sum: 0,
      current: null,
      showingStatus: this.STATUS_UNPRINTED,
      currentIndex: -1,
      searchFields: {
        forename: "",
        surname: "",
      },
      errorMessage: "",
      normalMessage: "",
    };
  },

  computed: {
    selectAll: {
      get: function() {
        return this.sum ? this.selected.length === this.sum : false
      },
      set: function(check) {
        this.clearMessages()
        if (check) {
          this.immunkartesGroupedByMandant.forEach(mandant => {
            mandant.immunkartes.forEach(immunkarte => {
              this.selected.push(immunkarte._id)
            })
          })
        } else {
          this.selected = []
        }
      }
    },

    isImmunkartePrinter() {
      return AuthService.hasRole(this.$store.state.auth.user, "ImmunkartePrinter")
    },
  },

  methods: {
    init() {
      this.getUnprintedImmunkartes()
    },

    clearMessages() {
      this.errorMessage = ""
      this.normalMessage = ""
    },

    moment(date) {
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },

    isAllImmunkartesInSelected(immunkartes) {
      return immunkartes.map(el => el._id).every(el => this.selected.includes(el))
    },

    selectAllMandant(immunkartes, check) {
      this.clearMessages()
      if (check) {
        immunkartes.forEach(immunkarte => {
          this.selected.push(immunkarte._id)
        })
      } else {
        immunkartes = immunkartes.map(el => el._id)
        this.selected.filter(el => {
          return !immunkartes.includes(el._id)
        })
        this.selected = this.selected.filter(el => !immunkartes.includes(el))
      }
    },

    updatePrintJob(immunkarteId, checked) {
      this.clearMessages()
      if (checked) {
        // enable permission
        this.selected.push(immunkarteId)
      } else {
        this.selected = this.selected.filter(
            el => el !== immunkarteId
        )
      }
    },

    printSelectedImmunkartes() {
      this.clearMessages()
      ImmunkarteService.printSelected(this.selected)
      .then(response => {
        // Download the returned blob
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'immunkarten.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // reset the view
        this.selected = []
        this.getUnprintedImmunkartes()
      })
      .catch((error) => {
        console.log(error.response.data.text().then(text => {
          this.errorMessage = JSON.parse(text).message
        }))

      })
    },

    loadImmunkartes(data) {
      this.sum = data.sum
      this.immunkartesGroupedByMandant = data.pairs
      this.setActiveImmunkarte(null)
    },

    getUnprintedImmunkartes() {
      this.showingStatus = this.STATUS_UNPRINTED

      ImmunkarteService.getAllUnprinted()
          .then(response => {
            console.log(response.data)
            this.loadImmunkartes(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    getImmunkartes() {
      this.showingStatus = this.STATUS_ALL

      ImmunkarteService.getAll()
          .then(response => {
            this.loadImmunkartes(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    toggleAllAndPrinted() {
      if (this.showingStatus === this.STATUS_ALL) this.getUnprintedImmunkartes()
      else this.getImmunkartes()
    },

    deleteImmunkarte() {
      ImmunkarteService.delete(this.current._id)
      .then(response => {
        this.refreshList()
        this.normalMessage = response.data.message;
      })
      .catch(e => {
        this.errorMessage = e.response.data.message;
      })
    },

    markImmunkarteUnprinted() {
      ImmunkarteService.markUnprinted(this.current._id)
      .then(response => {
        this.refreshList()
        this.normalMessage = response.data.message
      })
      .catch(reason => {
        this.errorMessage = reason.response.data.message
      })
    },

    searchByPatientData() {
      ImmunkarteService.findByPatientData(this.searchFields.forename, this.searchFields.surname, this.showingStatus === this.STATUS_UNPRINTED)
          .then(response => {
            this.loadImmunkartes(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    refreshList() {
      this.getImmunkartes();
      this.current = null;
      this.currentIndex = -1;
      this.normalMessage = "";
      this.errorMessage = "";
    },

    setActiveImmunkarte(immunkarte, index) {
      if (index === this.currentIndex) {
        this.setActiveImmunkarte(null)
      } else {
        this.current = immunkarte;
        this.currentIndex = immunkarte ? index : -1;
      }
    },

  },

  mounted() {
    this.init()
  }
};
</script>

<style scoped>
.list {
  text-align: left;
  max-width: 70vw;
  margin: auto;
}
li {
  cursor: pointer;
}

.scroll-container-vertical {
  max-height: 78vh;
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch
}

.detail-view {
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .scroll-container-vertical {
    max-height: 30vh;
  }

  .detail-view {
    margin-top: 0;
  }
}
</style>